<template>
    <div>
        <core-skill-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData"/>
        <vx-card>
            <div class="op-block mt-5 shadow-none p-0">
                <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="6">
                        <h3 class="font-bold h4" style="width: auto;">Core Skill</h3>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-lg="9" vs-sm="7" vs-xs="6">
                        <h3 class="font-bold h4">Description</h3>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="1" vs-sm="2" vs-xs="12">
                        <vs-button class="mr-sm-4 mr-0 mt-sm-0 mt-5" @click="addNewData">Add</vs-button>
                    </vs-col>
                    <vs-divider/>
                </vs-row>

                <vs-row :key="index" v-for="(item,index) in coreSkills">
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="2" vs-sm="3" vs-xs="12" class="pr-5" style="flex-wrap: wrap;">
                        <div class="large text-center">
                            <img v-if="item.icon != ''" :src="item.icon"
                                 class="inline-block" style="height:75px; object-fit: contain;">
                            <img v-else src="../../../../../../assets/images/personhood/no-img.png" width="100%" height="100%"
                                 class="inline-block" style="object-fit: scale-down">
                        </div>
                        <p class="h5 text-center" style="display: flex; flex: 0 0 100%; justify-content: center;">{{item.name}}</p>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-lg="9" vs-sm="7" vs-xs="12" class="pr-5">
                        <p class="my-sm-0 my-5 justify-text">{{item.description}}</p>
                    </vs-col>

                    <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="1" vs-sm="2" vs-xs="12">
                        <a href="#" @click.stop="editData(item)">
                        <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                                      />
                        </a>
                        <a href="#" @click.stop="deleteData(item._id)">
                        <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current"
                                      class="ml-2"
                                      />
                        </a>
                    </vs-col>
                    <vs-divider/>
                </vs-row>
            </div>
        </vx-card>

    </div>
</template>
<script>
    import CoreSkillSidebar from "./CoreSkillSidebar";
    import Swal from 'sweetalert2/dist/sweetalert2';
    import 'sweetalert2/src/sweetalert2.scss'
    //import moduleCoreSkill from "@/store/core-skill/moduleCoreSkill.js"
    import {mapActions} from "vuex";


    export default {
        components: {CoreSkillSidebar},
        data() {
            return {
                path: '',
                selected: [],
                isMounted: false,
                // Data Sidebar
                addNewDataSidebar: false,
                sidebarData: {},
                coreSkills:[]
            }
        },
        computed: {
            currentPage() {
                if (this.isMounted) {
                    return this.$refs.table.currentx
                }
                return 0
            },
        },
        methods: {
            ...mapActions("coreSkill", [
                "fetchCoreSkills",
                "deleteCoreSkill"
            ]),
            addNewData() {
                this.sidebarData = {}
                this.toggleDataSidebar(true)
            },
            showMessage(title, message, color) {
                this.$vs.notify({
                    title: title,
                    text: message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: color
                })
            },
            deleteData(id) {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.delete(id)
                    }
                })

            },
            async delete(id) {

                this.$vs.loading()
                this.deleteCoreSkill(id)
                    .then((res) => {
                        this.$vs.loading.close()
                        this.showMessage("Success", "Core Skill deleted successfully.", "success");
                    })
                    .catch(err => {
                        this.$vs.loading.close()
                        console.error(err)
                    })


                /*this.$store.dispatch("moduleCoreSkill/deleteCoreSkill", id)
                    .then((res) => {
                        this.showMessage("Success", "Core Skill deleted successfully.", "success");
                        //this.coreSkills = this.$store.state.moduleCoreSkill.coreSkills;
                    })
                    .catch(err => {
                        this.showMessage("Error", err.message, "warning");
                        console.error(err)
                    })*/
            },
            editData(data) {
                this.sidebarData = data
                this.toggleDataSidebar(true)
            },
            toggleDataSidebar(val = false) {
                this.addNewDataSidebar = val
            },
        },
        created() {

            this.$vs.loading()
            this.fetchCoreSkills()
                .then((res) => {
                    this.$vs.loading.close()
                    this.coreSkills = this.$store.state.coreSkill.coreSkills;
                })
                .catch(err => {
                    console.error(err)
                })

            /*if (!moduleCoreSkill.isRegistered) {
                this.$store.registerModule('moduleCoreSkill', moduleCoreSkill)
                moduleCoreSkill.isRegistered = true

                this.$store.dispatch("moduleCoreSkill/fetchCoreSkills")
                    .then((res) => {
                        this.coreSkills = this.$store.state.moduleCoreSkill.coreSkills;
                    })
                    .catch(err => {
                        console.error(err)
                    })
            }else{
                this.coreSkills = this.$store.state.moduleCoreSkill.coreSkills;
            }*/
        }
    }
</script>
<style scoped lang="scss">
  .justify-text {
    @media (max-width: 600px){
      text-align: justify;
    }
  }
</style>
